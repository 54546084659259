import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, Grid, List, ListItem, ListItemText, Button } from '@mui/material';
import { useNavigate, useLocation} from 'react-router-dom';

import api from '../Api/api'


export default function ObjectTable() {
  const navigate = useNavigate()
  const [objects, setObjects] = React.useState([]);


  React.useEffect(() => {
    getObjects();
  }, []);


  const getObjects = () => {
    api.getObjects()
      .then(function ({ data }) {
        setObjects(data);
        console.log(data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  function openObject(item) {
    navigate('/object/'+item.id, {state:item});
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {/* <TableCell align="right">Erstellt am</TableCell>
              <TableCell align="right">Erstellt von</TableCell>
              <TableCell align="right">Anbieter(Seite)</TableCell>
              <TableCell align="right">Titel</TableCell>
              <TableCell align="right">Preis</TableCell>
              <TableCell align="right">Preis pro qm</TableCell>
              <TableCell align="right">Räume</TableCell>
              <TableCell align="right">Wohnfläche</TableCell>
              <TableCell align="right">Grundstücksfläche</TableCell>
              <TableCell align="right">Baujahr</TableCell>
              <TableCell align="right">Beschreibung</TableCell>
              <TableCell align="right">Estimated Rental Income</TableCell>
              <TableCell align="right">Deal Indicator</TableCell>
              <TableCell align="right">Schlafzimmer</TableCell>
              <TableCell align="right">Badezimmer</TableCell>
              <TableCell align="right">Garage/Stellplatz</TableCell>
              <TableCell align="right">Objektzustand</TableCell>
              <TableCell align="right">Heizungsart</TableCell>
              <TableCell align='center'></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {objects.map((item, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                {/* <TableCell align="right">{item.created_at}</TableCell>
                <TableCell align="right">{item.creator}</TableCell>
                <TableCell align="right">{item.website}</TableCell>
                <TableCell align="right">{item.title}</TableCell>
                <TableCell align="right">{item.price}</TableCell>
                <TableCell align="right">{item.price_per_qm}</TableCell>
                <TableCell align="right">{item.rooms}</TableCell>
                <TableCell align="right">{item.living_space}</TableCell>
                <TableCell align="right">{item.land_area}</TableCell>
                <TableCell align="right">{item.construction_year}</TableCell>
                <TableCell align="right">{item.description}</TableCell>
                <TableCell align="right">{item.estimated_rental_income}</TableCell>
                <TableCell align="right">{item.deal_indicator}</TableCell>
                <TableCell align="right">{item.bedrooms}</TableCell>
                <TableCell align="right">{item.bathrooms}</TableCell>
                <TableCell align="right">{item.garage}</TableCell>
                <TableCell align="right">{item.object_condition}</TableCell>
                <TableCell align="right">{item.heating_type}</TableCell> */}
                <TableCell align='center'>
                  <Button variant='contained' sx={{mr:1}} onClick={() => openObject(item)} >Mehr</Button>
                  <Button variant="contained" color='error'>Entfernen</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
