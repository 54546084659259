import axios from 'axios';


/** base url */
export default axios.create({
    baseURL: '/api/v1',
    //baseURL: '/api/v1/immo',
    headers: {
        "Content-type": "application/json",
    }
});