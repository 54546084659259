import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, Grid, List, ListItem, ListItemText, Button, TextField, ListItemButton, ListItemAvatar, Avatar, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import FolderIcon from "@mui/icons-material/Folder";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import api from '../Api/api'


export default function ObjectList() {
  const navigate = useNavigate()
  const [objects, setObjects] = React.useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const [searchInput, setSearchInput] = useState([]);


  React.useEffect(() => {
    getObjects();
  }, []);


  const getObjects = () => {
    api.getObjects()
      .then(function ({ data }) {
        setObjects(data);
        console.log(data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = objects.filter((item) => {
        console.log(item)
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toString().toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(objects);
    }
  };
  const clearSearch = (e) => {
    setFilteredResults(objects);
    setSearchInput("");
  };

  function openObject(item) {
    navigate('/object/' + item.id, { state: item });
  }

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 5 }} justify="flex-start" alignItems="center">
        <Grid item xs={8}>
          <TextField
            id="filled-basic"
            label="Suchen..."
            variant="filled"
            autoFocus
            fullWidth
            type="text"
            value={searchInput}
            onChange={(e) => searchItems(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} justify="flex-start" alignItems="center">
        {searchInput.length > 1
          ? filteredResults.map((item, index) => (
            <Grid item xs={12} md={12} sx={{ paddingLeft: 2, paddingRight: 2 }} key={index}>
              <Paper elevation={2} sx={{ backgroundColor: "lightgray" }}>
                <Grid container sx={{ pt: 2, pb: 2 }}>
                  <Grid item xs={3}>
                    <Card elevation={0} sx={{ backgroundColor: "transparent" }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="350"
                          image="/images/example_image.jpg"
                          alt="green iguana"
                          sx={{ objectFit: "contain", marginLeft: 0 }}
                        />
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={9} paddingLeft={2}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography component="h1" variant='h4'>{item.title}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Good-Deal-Indicator</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{item.price} €</Typography>
                        <Typography>Preis pro qm</Typography>
                        <Typography>Zimmer</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Adresse</Typography>
                        <Typography>Adresse</Typography>
                        <Typography>Adresse</Typography>
                        <Typography>Adresse</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>Icons</Typography>
                        <Typography>Icons</Typography>
                        <Typography>Icons</Typography>
                        <Typography>Icons</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>Buttons</Typography>
                        <Typography>Buttons</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
          : objects.map((item, index) => (
            <Grid item xs={12} md={12} sx={{ paddingLeft: 2, paddingRight: 2 }} key={index}>
              <Paper elevation={2} sx={{ backgroundColor: "lightgray" }}>
                <Grid container sx={{ pt: 2, pb: 2 }}>
                  <Grid item xs={3}>
                    <Card elevation={0} sx={{ backgroundColor: "transparent" }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="350"
                          image="/images/example_image.jpg"
                          alt="green iguana"
                          sx={{ objectFit: "contain", marginLeft: 0 }}
                        />
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={9} paddingLeft={2}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography
                          component="h1"
                          variant='h4'
                          onClick={() => openObject(item)}
                        >
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Good-Deal-Indicator</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{item.price} €</Typography>
                        <Typography>Preis pro qm</Typography>
                        <Typography>Zimmer</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Adresse</Typography>
                        <Typography>Adresse</Typography>
                        <Typography>Adresse</Typography>
                        <Typography>Adresse</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>Icons</Typography>
                        <Typography>Icons</Typography>
                        <Typography>Icons</Typography>
                        <Typography>Icons</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>Buttons</Typography>
                        <Typography>Buttons</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
