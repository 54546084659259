import React from 'react'
import { Button, Container, Grid, Paper, Typography } from '@mui/material'

import CreateConstructionStatus from '../Components/ConstructionStatus/Create';
import ConstructionStatusList from '../Components/ConstructionStatus/List';

export default function ConstructionStatus() {
  return (
    <Container maxWidth={false} sx={{mt: 10, mb: 4}}>
        <Grid container spacing={3}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <Typography component="h1" sx={{fontSize:32}}>Bauphase</Typography>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3} sx={{mt: 3, mb: 4}}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <CreateConstructionStatus/>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={5}>
            <Grid item xs={14}>
                <ConstructionStatusList />
            </Grid>
        </Grid>
    </Container>
  );
}
