import React from 'react'
import { Button, Container, Grid, Paper, Typography } from '@mui/material'

import CreateUser from '../Components/User/Create';
import UserList from '../Components/User/List';

export default function User() {
  return (
    <Container maxWidth={false} sx={{mt: 10, mb: 4}}>
        <Grid container spacing={3}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <Typography component="h1" sx={{fontSize:32}}>Benutzer</Typography>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3} sx={{mt: 3, mb: 4}}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <CreateUser/>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={5}>
            <Grid item xs={14}>
                <UserList />
            </Grid>
        </Grid>
    </Container>
  );
}
