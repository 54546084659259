import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import api from '../../Api/api'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateConstructionStatus() {
    const [open, setOpen] = React.useState(false);
    const [constatus, setConstatus] = React.useState({})
    const [snackbarOpens, setSnackbarOpens] = React.useState({
        message: "Info",
        success: "success",
        opens: false,
    });
    const [textError, setTextError] = React.useState({
        constatus: false
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

      /** Ermöglicht den Input von neuen Daten */
    const handleInputChange = (event) => {
        console.log(event.target.value);
        const { name, value } = event.target;
        setConstatus({ ...constatus, [name]: value })
    }

    const createConstatus = () => {
        console.log(constatus)
        var data = {
            id: 0,
            status: constatus.status,
            description: constatus.description,
            created_at: "",
            updated_at: ""
        }
        console.log(data)
        api.postConstatus(data)
            .then(constatus => {
                setSnackbarOpens({
                    message: "Erstellen war erfolgreich!",
                    msg: "success",
                    snackbarOpens:true,
                });
                handleClose()
            }).catch((e) => {
                console.log(e)
                setSnackbarOpens({
                    message: "Erstellen war nicht erfolgreich!",
                    msg: "error",
                    snackbarOpens: true,
                });
            });
    }

    /**Färbt die Textboxen in rot ein, wenn nicht alle ausgefüllt sind. */
    const textInRed = () => {
        setTextError({
        description:true
        })
        setSnackbarOpens({
        message: "Erstellen war nicht erfolgreich!",
        msg: "error",
        snackbarOpens: true,
        });
    }

    const handleCloses = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }

        setSnackbarOpens({
        snackbarOpens: false,
        });
    };

    return (
        <>
            <Button aria-label="create" variant="contained" onClick={handleClickOpen}>
                Bauphase erstellen
            </Button>
            <Snackbar
                onClose={handleCloses}
                open={snackbarOpens.snackbarOpens}
                autoHideDuration={4000}
            >
                <Alert
                onClose={handleCloses}
                severity={snackbarOpens.msg}
                sx={{ width: "100%" }}
                >
                    {snackbarOpens.message}
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Bauphase erstellen</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="outlined-start-adornment"
                                label="Status"
                                type="text"
                                fullWidth
                                variant="standard"
                                name="status"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                multiline
                                margin="dense"
                                id="outlined-start-adornment"
                                label="Beschreibung"
                                type="text"
                                fullWidth
                                variant="standard"
                                name="description"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error" endIcon={<CloseIcon />}>
                        Abbrechen
                    </Button>
                    <Button onClick={createConstatus} color="success" endIcon={<SaveIcon />}>
                        Erstellen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
