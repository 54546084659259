import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, Grid, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import ModifyProptype from './Modify'
import DeleteProptype from './Delete';

import api from '../../Api/api'


export default function PropertyTypesList() {
    const navigate = useNavigate()
    const [proptypes, setProptypes] = React.useState([]);


    React.useEffect(() => {
        getProptypes();
    }, []);


    const getProptypes = () => {
        api.getPropertyTypes()
            .then(function ({ data }) {
                setProptypes(data);
                console.log(data);
            })
            .catch(function (error) {
                console.error(error);
            });
    };


    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Erstellt am</TableCell>
                            <TableCell align="left">Zuletzt aktualisiert am</TableCell>
                            <TableCell align="left">Typ</TableCell>
                            <TableCell align="left">Beschreibung</TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {proptypes.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {item.id}
                                </TableCell>
                                <TableCell align="left">{item.created_at}</TableCell>
                                <TableCell align="left">{item.updated_at}</TableCell>
                                <TableCell align="left">{item.type}</TableCell>
                                <TableCell align="left">{item.description}</TableCell>
                                <TableCell align='right'>
                                    <ModifyProptype data = {item}/>
                                    <DeleteProptype data = {item}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
