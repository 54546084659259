import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';

export default function NewObject() {
    const [open, setOpen] = React.useState(false);
    const [object, setObject] = React.useState("")
    const [provider, setProvider] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

      /** Ermöglicht den Input von neuen Daten */
    const handleInputChange = (event) => {
        console.log(event.target.value);
        const { name, value } = event.target;
        setObject({ ...object, [name]: value })
    }
    const handleChange = (event) => {
        setProvider(event.target.value);
      };

    const createObject = () => {
        //console.log(provider)
        console.log(object)
        handleClose();
    };

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Neues Objekt
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Neues Objekt erstellen</DialogTitle>
                <DialogContent>
                    <DialogContentText>Erstellen eines neuen Objektes</DialogContentText>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="creator"
                                label="Erstellt von"
                                type="text"
                                fullWidth
                                variant="standard"
                                name="creator"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>
                            <InputLabel id="demo-select-small-label">Anbieter</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="website"
                                value={provider}
                                label="Anbieter"
                                onChange={handleChange}
                            >
                                <MenuItem value={"immoscout"}>immoscout</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                autoFocus
                                multiline
                                margin="dense"
                                id="title"
                                label="Titel"
                                type="text"
                                fullWidth
                                variant="standard"
                                name="title"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="price"
                                label="Kaufpreis"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                fullWidth
                                variant="standard"
                                name="price"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="price_per_qm"
                                label="Preis pro qm"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                fullWidth
                                variant="standard"
                                name="price_per_qm"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="living_space"
                                label="Wohnfläche"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">qm</InputAdornment>,
                                }}
                                fullWidth
                                variant="standard"
                                name="living_space"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="land_area"
                                label="Grundstück"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">qm</InputAdornment>,
                                }}
                                fullWidth
                                variant="standard"
                                name="land_area"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Abbrechen
                    </Button>
                    <Button onClick={createObject} color="success">
                        Erstellen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
