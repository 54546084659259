import axios from './axios';


// Auslesen aller Objekte
const getObjects = () => {
    return axios.get('/object');
};

const getObjectList = () => {
    return axios.get('/objectlist');
};


/*
    LiveSearch
*/
// Suchen nach Objekten
const getLiveSearch = (searchData, page) => {
    return axios.post('immowelt', searchData, page);
}

// Search in specific city
const postLiveSearch = data => {
    return axios.post('immowelt', data);
}

/* 
    PropertyTypes
*/
//Auslesen aller PropertyTypes
const getPropertyTypes = () => {
    return axios.get('/propertytype')
}
//Erstellen eines PropertyTypes
const postPropertyTypes = data => {
    return axios.post('/propertytype', data);
}
//Modify PropertyTypes
const putPropertyTypes = (id, data) => {
    return axios.put('/propertytype/'+id, data);
}
//Delete PropertyTypes
const deletePropertyTypes = id => {
    return axios.delete('/propertytype/'+id);
}

/*
    PropertyConditions
*/
//Get all PropertyConditions
const getPropertyConditions = () => {
    return axios.get('/propertyconditions')
}
//Create new PropertyCondition
const postPropertyConditions = data => {
    return axios.post('/propertyconditions', data);
}
//Modify PropertyCondition
const putPropertyConditions = (id, data) => {
    return axios.put('/propertyconditions/'+id, data);
}
//Delete PropertyCondition
const deletePropertyConditions = id => {
    return axios.delete('/propertyconditions/'+id);
}

/*
    Furnishing
*/
//Get all Furnishings
const getFurnishing = () => {
    return axios.get('/furnishing');
}
//Create new Furnishing
const postFurnishing = data => {
    return axios.post('/furnishing', data);
}
//Modify Furnishing
const putFurnishing = (id, data) => {
    return axios.put('/furnishing/'+id, data);
}
//Delete Furnishing
const deleteFurnishing = id => {
    return axios.delete('/furnishing/'+id);
}

/*
    Seller
*/
//Get all Seller
const getSeller = () => {
    return axios.get('/seller');
}
//Create new Seller
const postSeller = data => {
    return axios.post('/seller', data);
}
//Modify Seller
const putSeller = (id, data) => {
    return axios.put('/seller/'+id, data);
}
//Delete Seller
const deleteSeller = id => {
    return axios.delete('/seller/'+id);
}

/*
    User
*/
//Get all Seller
const getUser = () => {
    return axios.get('/user');
}
//Create new Seller
const postUser = data => {
    return axios.post('/user', data);
}
//Modify Seller
const putUser = (id, data) => {
    return axios.put('/user/'+id, data);
}
//Delete Seller
const deleteUser = id => {
    return axios.delete('/user/'+id);
}

/*
    construction status
*/
//Get all Seller
const getConstatus = () => {
    return axios.get('/constructionstatus');
}
//Create new Seller
const postConstatus = data => {
    return axios.post('/constructionstatus', data);
}
//Modify Seller
const putConstatus = (id, data) => {
    return axios.put('/constructionstatus/'+id, data);
}
//Delete Seller
const deleteConstatus = id => {
    return axios.delete('/constructionstatus/'+id);
}

/*
    energy certificates
*/
//Get all Seller
const getEcert = () => {
    return axios.get('/energycertificates');
}
//Create new Seller
const postEcert = data => {
    return axios.post('/energycertificates', data);
}
//Modify Seller
const putEcert = (id, data) => {
    return axios.put('/energycertificates/'+id, data);
}
//Delete Seller
const deleteEcert = id => {
    return axios.delete('/energycertificates/'+id);
}



export default {
    getObjects,
    //PropertyTypes
    getPropertyTypes,
    postPropertyTypes,
    putPropertyTypes,
    deletePropertyTypes,
    //PropertyConditions
    getPropertyConditions,
    postPropertyConditions,
    putPropertyConditions,
    deletePropertyConditions,
    //Furnishing
    getFurnishing,
    postFurnishing,
    putFurnishing,
    deleteFurnishing,
    //Seller
    getSeller,
    postSeller,
    putSeller,
    deleteSeller,
    //User
    getUser,
    postUser,
    putUser,
    deleteUser,
    //construction status
    getConstatus,
    postConstatus,
    putConstatus,
    deleteConstatus,
    //energy certificates
    getEcert,
    postEcert,
    putEcert,
    deleteEcert,
    //LiveSearch
    getLiveSearch
};