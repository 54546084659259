import React, { useEffect, useState, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
    Typography,
    Button,
    Divider,
    LinearProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ImageSlider from "../Components/ImageSlider";
import { green } from "@mui/material/colors";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

//ICONS
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

export default function Object() {
    const navigate = useNavigate();
    const location = useLocation();

    console.log(location.state);

    const slides = [
        { url: "/images/example_image.jpg", title: "beach" },
        { url: "/logo512.png", title: "boat" },
    ];
    const containerStyles = {
        width: "75vw",
        height: "65vh",
        margin: "0 auto",
    };

    const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

    return (
        <>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <div style={containerStyles}>
                    <ImageSlider slides={slides} />
                </div>
                <Grid container spacing={3} sx={{ mt: 5 }}>
                    <Grid item xs={12}>
                        <Paper sx={{ pt: 2, pl: 2, pr: 2, pb: 1, display: "flex", flexDirection: "column", borderBottom: `10px solid ${green[500]}` }}>
                            <Typography
                                component="h2"
                                variant="h4"
                                color="primary"
                                gutterBottom
                            >
                                {location.state.title}
                            </Typography>
                            <Grid container>
                                <Grid item sx={6}>
                                    <Typography
                                        component="h6"
                                        variant="h6"
                                        sx={{ mb: 0, pb: 0 }}
                                        color="primary"
                                        gutterBottom
                                    >
                                        Good-Deal-Indicator
                                    </Typography>
                                </Grid>
                                <Grid item sx={4}>
                                    <Tooltip title={longText} placement="top-start" arrow>
                                        <InfoOutlinedIcon sx={{ mb: 0, pb: 0 }} />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={1}
                            >
                                <Grid item sx={12}>
                                    <Grid container direction="row">
                                        <Grid item xs={6}>
                                            <Typography align="left">
                                                Erstellt am:{" "}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left">
                                                {location.state !== null ? location.state.created_at : <></>}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left">Erstellt von: </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left">
                                                {location.state !== null ? location.state.creator : <></>}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sx={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography align="left">
                                                Modifiziert am:{" "}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left">
                                                {location.state !== null ? location.state.created_at : <></>}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left">Modifiziert von: </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left">
                                                {location.state !== null ? location.state.creator : <></>}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sx={12}>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item sx={12}>
                                            <Button variant="outlined" startIcon={<CalculateOutlinedIcon />}>
                                                Rechner
                                            </Button>
                                        </Grid>
                                        <Grid item sx={12}>
                                            <Button variant="outlined" startIcon={<ApartmentOutlinedIcon />}>
                                                Objekt durchrechnen
                                            </Button>
                                        </Grid>
                                        <Grid item sx={12}>
                                            <Button variant="outlined" startIcon={<ShoppingCartOutlinedIcon />}>
                                                Kaufprozess starten
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 4,
                                mb: 2,
                                display: "flex",
                                flexDirection: "row",
                                justify: "flex-start",
                                textAlign: "left",
                            }}
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={10}>
                                    <Typography
                                        align="left"
                                        component="h2"
                                        variant="h4"
                                        color="primary"
                                        gutterBottom
                                    >
                                        {location.state !== null ? location.state.title : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Kaufpreis: {location.state !== null ? location.state.price : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Preis pro qm: {location.state !== null ? location.state.price_per_qm : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Wohnfläche: {location.state !== null ? location.state.living_space : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Grundstücksfläche: {location.state !== null ? location.state.land_area : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography mb={2}>
                                        Zimmer: {location.state !== null ? location.state.rooms : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography mb={2}>
                                        Schlafzimmer: {location.state !== null ? location.state.bedrooms : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography mb={2}>
                                        Badezimmer: {location.state !== null ? location.state.bathrooms : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography mb={2}>
                                        Garage/Stellplatz: {location.state !== null ? location.state.garage : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Baujahr: {location.state !== null ? location.state.construction_year : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Objektzustand: {location.state !== null ? location.state.object_condition : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography mb={2}>
                                        Heizungsart: {location.state !== null ? location.state.heating_type : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Deal Indikator: {location.state !== null ? location.state.deal_indicator : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography mb={2}>
                                        Estimated Rental Income: {location.state !== null ? location.state.estimated_rental_income : <></>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        align="left"
                                        component="h2"
                                        variant="h4"
                                        color="primary"
                                        gutterBottom
                                    >
                                        Beschreibung
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography mb={2}>
                                        {location.state !== null ? location.state.description : <></>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
