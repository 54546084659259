import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Box from "@mui/material/Box";

import Dashboard from './Pages/Dashboard'
import Object from './Pages/Object';

import Layout from './Components/Layout'
import PropertyTypes from './Pages/PropertyTypes';
import PropertyConditions from './Pages/PropertyConditions';
import Furnishing from './Pages/Furnishing';
import Seller from './Pages/Seller';
import User from './Pages/User';
import EnergyCertificates from './Pages/EnergyCertificates';
import ConstructionStatus from './Pages/ConstructionStatus';
import ObjectList from './Pages/ObjectList';
import LiveSearch from './Pages/LiveSearch';

function App() {
  return (
    <div className="App">
      <Box sx={{ display: "flex" }}>
        <>
          <Layout />
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route exact path='/object/:id' element={<Object />} />
            {/* <Route exact path='/objectlist' element={<ObjectList />} /> */}
            <Route exact path='/livesearch' element={<LiveSearch />} />
            <Route exact path='/propertytypes' element={<PropertyTypes />} />
            <Route exact path='/propertyconditions' element={<PropertyConditions />} />
            <Route exact path='/furnishing' element={<Furnishing />} />
            <Route exact path='/seller' element={<Seller />} />
            <Route exact path='/user' element={<User />} />
            <Route exact path='/energycertificates' element={<EnergyCertificates />} />
            <Route exact path='/constructionstatus' element={<ConstructionStatus />} />
          </Routes>
        </>
      </Box>
    </div>
  );
}

export default App;
