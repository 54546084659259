import React, { useState } from 'react'
import { Button, Container, FormControl, FormControlLabel, FormGroup, Select, MenuItem, Grid, Paper, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@mui/material'
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import ObjectTable from "../Components/ObjectTable"
import ObjectList from "../Components/ObjectList"
import NewObject from '../Components/NewObject';

import api from '../Api/api'

export default function Dashboard(props) {
    const [objects, setObjects] = useState([]);
    const [postleitzahl, setPostleitzahl] = useState('');
    const [umkreis, setUmkreis] = useState('');
    const [preisVon, setPreisVon] = useState('');
    const [preisBis, setPreisBis] = useState('');
    const [wohnflächeVon, setWohnflächeVon] = useState('');
    const [wohnflächeBis, setWohnflächeBis] = useState('');
    const [hausChecked, setHausChecked] = useState(false);
    const [wohnungChecked, setWohnungChecked] = useState(false);
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (property) => {
        const isAsc = sortBy === property && sortOrder === 'asc';
        setSortBy(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
    };

    const sortedObjects = objects.slice().sort((a, b) => {
        const isAsc = sortOrder === 'asc';
        if (sortBy) {
            const aValue = sortBy === 'squareMeter' ? parseInt(a[sortBy].replace(/[^\d.,]/g, '').replace(',', '.'), 10) : a[sortBy];
            const bValue = sortBy === 'squareMeter' ? parseInt(b[sortBy].replace(/[^\d.,]/g, '').replace(',', '.'), 10) : b[sortBy];
            return (isAsc ? aValue > bValue : aValue < bValue) ? 1 : -1;
        }
        return 0;
    });

    const handleUmkreisChange = (event) => {
        setUmkreis(event.target.value);
    };

    const handlePageCount = (numberObjects) => {
        if (page === 0) {
            if (numberObjects > 20) {
                // Calculate the number of pages and round up
                const pages = Math.ceil(numberObjects / 20);
                console.log("Pages:",pages);
                setPage(pages);
            }
            else {
                setPage(1);
            }
            setCurrentPage(1);
        }
    }
    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        console.log("Current Page:",value);
        const searchData = {
            postleitzahl,
            umkreis,
            preisVon,
            preisBis,
            wohnflächeVon,
            wohnflächeBis,
            hausChecked,
            wohnungChecked,
            page
        };
        api.getLiveSearch(searchData, value)
            .then(function ({ data }) {
                console.log(data.objects);
                setObjects(data.objects);
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const getObjects = (searchData) => {
        api.getLiveSearch(searchData, 0)
            .then(function ({ data }) {
                console.log(data.objects);
                setObjects(data.objects);
                console.log(data.numberObjects);
                handlePageCount(data.numberObjects);
            })
            .catch(function (error) {
                console.error(error);
            });
    };


    const handleSearch = () => {
        // Call getObjects function with the values from the TextFields
        const searchData = {
            postleitzahl,
            umkreis,
            preisVon,
            preisBis,
            wohnflächeVon,
            wohnflächeBis,
            hausChecked,
            wohnungChecked,
            page
        };
        console.log(searchData)
        getObjects(searchData);
    };


    return (
        <Container maxWidth={false} sx={{ mt: 10, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={14}>
                    <Paper
                        elevation={0}>
                        <Typography component="h1" sx={{ fontSize: 32 }}>Immomentor</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3, mb: 4 }}>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <TextField
                            id="outlined-basic"
                            label="Postleitzahl"
                            variant="outlined"
                            value={postleitzahl}
                            onChange={(e) => setPostleitzahl(e.target.value)}
                        />
                        <Select
                            label="Umkreis (km)"
                            value={umkreis}
                            onChange={handleUmkreisChange}
                            displayEmpty
                            sx={{ minWidth: 200 }}
                        >
                            {/* Define Select options using MenuItem */}
                            <MenuItem value="">Umkreis (km)</MenuItem>
                            <MenuItem value={0}>kein Umkreis</MenuItem>
                            <MenuItem value={1}>1 km</MenuItem>
                            <MenuItem value={2}>2 km</MenuItem>
                            <MenuItem value={3}>3 km</MenuItem>
                            <MenuItem value={5}>5 km</MenuItem>
                            <MenuItem value={10}>10 km</MenuItem>
                            <MenuItem value={20}>20 km</MenuItem>
                            <MenuItem value={30}>30 km</MenuItem>
                            <MenuItem value={50}>50 km</MenuItem>
                            {/* Add more options as needed */}
                        </Select>
                        <TextField
                            id="outlined-basic"
                            label="Preis von ..."
                            variant="outlined"
                            value={preisVon}
                            onChange={(e) => setPreisVon(e.target.value)}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Preis bis ..."
                            variant="outlined"
                            value={preisBis}
                            onChange={(e) => setPreisBis(e.target.value)}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Wohnfläche von ..."
                            variant="outlined"
                            value={wohnflächeVon}
                            onChange={(e) => setWohnflächeVon(e.target.value)}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Wohnfläche bis ..."
                            variant="outlined"
                            value={wohnflächeBis}
                            onChange={(e) => setWohnflächeBis(e.target.value)}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Haus"
                                checked={hausChecked}
                                onChange={(e) => setHausChecked(e.target.checked)}
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Wohnung"
                                checked={wohnungChecked}
                                onChange={(e) => setWohnungChecked(e.target.checked)}
                            />
                        </FormGroup>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3, mb: 4 }}>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Button variant="contained" onClick={handleSearch}>
                            Search
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3, mb: 4 }}>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Button onClick={() => handleSort('name')}>
                                                Link
                                                {sortBy === 'name' ? (
                                                    sortOrder === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSort('price')}>
                                                Preis
                                                {sortBy === 'price' ? (
                                                    sortOrder === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSort('squareMeter')}>
                                                Quadratmeter
                                                {sortBy === 'squareMeter' ? (
                                                    sortOrder === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSort('price_per_squareMeter')}>
                                                Preis pro m²
                                                {sortBy === 'price_per_squareMeter' ? (
                                                    sortOrder === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSort('estRental')}>
                                                Est. Mietpreis
                                                {sortBy === 'estRental' ? (
                                                    sortOrder === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSort('rentalprice_per_qm')}>
                                                Mietpreis pro m²
                                                {sortBy === 'rentalprice_per_qm' ? (
                                                    sortOrder === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleSort('grossReturn')}>
                                                Brutto-Rendite
                                                {sortBy === 'grossReturn' ? (
                                                    sortOrder === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                        {/* Add more columns as needed */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedObjects.map(object => (
                                        <TableRow key={object.id}>
                                            <TableCell><a href={object.url} target="_blank" rel="noreferrer">{object.url}</a></TableCell>
                                            <TableCell>{object.price} €</TableCell>
                                            <TableCell>{object.squareMeter} m²</TableCell>
                                            <TableCell>{object.price_per_squareMeter} €</TableCell>
                                            <TableCell>{object.estRental} €</TableCell>
                                            <TableCell>{object.rentalprice_per_qm} €</TableCell>
                                            <TableCell>{object.grossReturn} %</TableCell>
                                            {/* Add more cells as needed */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <Paper elevation={0}>
                        
                    </Paper>
                </Grid>
                
            </Grid>
            {page > 0 && ( // Render Pagination only if page is greater than 0 */}
            <Grid container spacing={3} sx={{ mt: 5, mb: 4 }} direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Stack spacing={2}>
                            <Pagination count={page} page={currentPage} onChange={handlePaginationChange} color="primary" />
                    </Stack>
                </Grid>
            </Grid>
            )}
        </Container>
    );
}
