import React from 'react'
import { Button, Container, Grid, Paper, Typography } from '@mui/material'

import CreateSeller from '../Components/Seller/Create';
import SellerList from '../Components/Seller/List';

export default function Seller() {
  return (
    <Container maxWidth={false} sx={{mt: 10, mb: 4}}>
        <Grid container spacing={3}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <Typography component="h1" sx={{fontSize:32}}>Verkäufer</Typography>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3} sx={{mt: 3, mb: 4}}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <CreateSeller/>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={5}>
            <Grid item xs={14}>
                <SellerList />
            </Grid>
        </Grid>
    </Container>
  );
}
