import React from 'react'
import { Button, Container, Grid, Paper, Typography } from '@mui/material'

import CreateFurn from '../Components/Furnishing/Create';
import FurnishingList from '../Components/Furnishing/List';

export default function Furnishing() {
  return (
    <Container maxWidth={false} sx={{mt: 10, mb: 4}}>
        <Grid container spacing={3}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <Typography component="h1" sx={{fontSize:32}}>Ausstattung</Typography>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3} sx={{mt: 3, mb: 4}}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <CreateFurn/>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={5}>
            <Grid item xs={14}>
                <FurnishingList />
            </Grid>
        </Grid>
    </Container>
  );
}
