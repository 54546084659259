import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import api from '../../Api/api'

export default function ModifyFurn(props) {
    const [open, setOpen] = React.useState(false);
    const [furnishing, setFurnishing] = React.useState(props.data)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

      /** Ermöglicht den Input von neuen Daten */
    const handleInputChange = (event) => {
        console.log(event.target.value);
        const { name, value } = event.target;
        setFurnishing({ ...furnishing, [name]: value })
    }

    const changeFurn = () => {
        //console.log(provider)
        console.log(furnishing)
        api.putFurnishing(furnishing.id, furnishing)
            .then((resp) => {
                console.log(resp.data);
            })
            .catch((e) => {
                console.log(e)
            })
        handleClose();
    };

    return (
        <>
            <IconButton aria-label="modify" onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Ausstattung bearbeiten</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                disabled
                                margin="dense"
                                id="outlined-read-only-input"
                                label="ID"
                                defaultValue={furnishing.id}
                                type="text"
                                fullWidth
                                variant="standard"
                                name="id"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                disabled
                                margin="dense"
                                id="outlined-read-only-input"
                                label="Erstellt am"
                                defaultValue={furnishing.created_at}
                                type="text"
                                fullWidth
                                variant="standard"
                                name="created_at"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                disabled
                                margin="dense"
                                id="outlined-read-only-input"
                                label="Zuletzt aktualisiert am"
                                defaultValue={furnishing.updated_at}
                                type="text"
                                fullWidth
                                variant="standard"
                                name="updated_at"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="outlined-start-adornment"
                                label="Ausstattung"
                                defaultValue={furnishing.furnish}
                                type="text"
                                fullWidth
                                variant="standard"
                                name="furnish"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                multiline
                                margin="dense"
                                id="outlined-start-adornment"
                                label="Beschreibung"
                                defaultValue={furnishing.description}
                                type="text"
                                fullWidth
                                variant="standard"
                                name="description"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error" endIcon={<CloseIcon />}>
                        Abbrechen
                    </Button>
                    <Button onClick={changeFurn} color="success" endIcon={<SaveIcon />}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
