import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../Api/api'

export default function DeleteConstatus(props) {
    const [open, setOpen] = React.useState(false);
    const [constatus, setConstatus] = React.useState(props.data)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

      /** Ermöglicht den Input von neuen Daten */
    const handleInputChange = (event) => {
        console.log(event.target.value);
        const { name, value } = event.target;
        setConstatus({ ...constatus, [name]: value })
    }

    const deleteConstatus = () => {
        //console.log(provider)
        console.log(constatus)
        api.deleteConstatus(constatus.id)
            .then((resp) => {
                console.log(resp.data);
            })
            .catch((e) => {
                console.log(e)
            })
        handleClose();
    };

    return (
        <>
            <IconButton color="error" aria-label="delete" onClick={handleClickOpen}>
                <DeleteIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Möchtest du die Bauphase löschen?</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography>{constatus.id}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} endIcon={<CloseIcon />}>
                        Abbrechen
                    </Button>
                    <Button onClick={deleteConstatus} color="error" endIcon={<DeleteIcon />}>
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
