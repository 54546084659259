import React from 'react'
import { Button, Container, Grid, Paper, Typography } from '@mui/material'

import ObjectTable from "../Components/ObjectTable"
import ObjectList from "../Components/ObjectList"
import NewObject from '../Components/NewObject';

export default function Dashboard() {
  return (
    <Container maxWidth={false} sx={{mt: 10, mb: 4}}>
        <Grid container spacing={3}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <Typography component="h1" sx={{fontSize:32}}>Immomentor</Typography>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={3} sx={{mt: 3, mb: 4}}>
            <Grid item xs={14}>
                <Paper
                    elevation={0}>
                        <NewObject/>
                    </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={5} sx={{mt: 3, mb: 8}}>
            <Grid item xs={14}>
                <ObjectList />
            </Grid>
        </Grid>
        <Grid container spacing={5}>
            <Grid item xs={14}>
                <ObjectTable />
            </Grid>
        </Grid>
    </Container>
  );
}
