import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

//Icon Imports
import Icon from '@mui/material/Icon';
//import { Icon } from "@mui/material";
//import Icon from '@mui/material/Icon';

//Imports for Account Menu
import DashboardIcon from "@mui/icons-material/Dashboard";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HistoryIcon from '@mui/icons-material/History';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';

import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const useStyles = makeStyles({
    page: {
        width: "100%",
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const menu = [
    {
        name: "Dashboard",
        nav: "/",
        icon: "dashboard",
    },
    {
        name: "Live Suche",
        nav: "livesearch",
        icon: "ScreenSearchDesktopIcon",
    },
    {
        name: "Objektliste",
        nav: "/objectlist",
        icon: "propertytypes",
    },
    {
        name: "Objekttyp",
        nav: "/propertytypes",
        icon: "propertytypes",
    },
    {
        name: "Objektzustand",
        nav: "/propertyconditions",
        icon: "history"
    },
    {
        name: "Ausstattung",
        nav: "/furnishing",
        icon: "business_center",
    },
    {
        name: "Verkäufer",
        nav: "/seller",
        icon: "business_center",
    },
    {
        name: "Benutzer",
        nav: "/user",
        icon: "business_center",
    },
    {
        name: "Energieausweis",
        nav: "/energycertificates",
        icon: "business_center",
    },
    {
        name: "Bauphase",
        nav: "/constructionstatus",
        icon: "business_center",
    },
];

const secMenu = [
    {
        name: "Account",
        nav: "/account",
        icon: "person",
    },
    {
        name: "Über Uns",
        nav: "/about",
        icon: "groups",
    },
];

const accMenu = [
    {
        name: "Account",
        nav: "/account",
        icon: "manage_accounts"
    },
    {
        name: "DarkMode",
        nav: "",
        icon: "dark_mode"
    },
    {
        name: "Logout",
        nav: "",
        icon: "logout"
    }
]

export default function MiniDrawer({ children }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    function navigateTo(link) {
        navigate(link);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    /** Ermöglicht das Öffnen und Schließen des Account-Menüs */
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [anchorElUser, setAnchorElUser] = useState(null);

    /** Ermöglicht die Funktionalität des Account-Menüs.
     * Ermöglicht Weiterleitung auf Seite Account und Logout von der Verwaltungssystem.
     */
    const handleAccountMenu = (i) => {
        if (i === 0) {
            console.log("Account");
            navigate("/account");
        }
        if (i === 1) {
            // if(context.darkMode === false){
            //   context.setDarkMode(true)
            // }
            // if(context.darkMode === true) {
            //   context.setDarkMode(false)
            // }
        }
        if (i === 2) {
            console.log("Logout");
            sessionStorage.removeItem("Auth Token");
            sessionStorage.removeItem("name");
            sessionStorage.removeItem("profilePic");
            document.cookie = "token=;path=/";
            // logout();
            navigate("/");
        }
        handleCloseUserMenu()
    };

    return (
        <div className="Navigation">
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Immomentor
                        </Typography>

                        {/** Account-Menü  */}
                        <div style={{ display: "flex", marginLeft: "auto" }}>
                            <div>
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{
                                        p: 0,
                                        ml: "auto",
                                        mr: 1,
                                        "&.MuiButtonBase-root:hover": {
                                            bgcolor: "transparent",
                                        },
                                    }}
                                >
                                    <Avatar
                                        sx={{ mr: 1 }}
                                        alt="Google Profile Picture"
                                        src={sessionStorage.getItem("profilePic")}
                                    />
                                    <Typography color={"white"}>
                                        {sessionStorage.getItem("name")}
                                    </Typography>
                                </IconButton>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {accMenu.map((item, index) => (
                                        <Paper elevation={0} sx={{ width: 150, maxWidth: '100%' }}>
                                            <MenuItem
                                                key={item.name}
                                                onClick={() => handleAccountMenu(index)}>
                                                <ListItemIcon >
                                                    <Icon key={item.icon} fontSize="small">{item.icon}</Icon>
                                                </ListItemIcon>
                                                <ListItemText >{item.name}</ListItemText>
                                            </MenuItem>
                                        </Paper>
                                    ))}
                                </Menu>
                            </div>
                        </div>

                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {menu.map((item, index) => (
                            <ListItemButton
                                key={item.name}
                                onClick={() => navigateTo(item.nav)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    {item.icon === 'dashboard' && <DashboardIcon />}
                                    {item.icon === 'ScreenSearchDesktopIcon' && <ScreenSearchDesktopIcon />}
                                    {item.icon === 'propertytypes' && <HolidayVillageIcon />}
                                    {item.icon === 'history' && <HistoryIcon />}
                                    {item.icon === 'business_center' && <BusinessCenterIcon />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.name}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {secMenu.map((item, index) => (
                            <ListItemButton
                                key={item.name}
                                onClick={() => navigateTo(item.nav)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >

                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Icon key={item.icon}>{item.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.name}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Drawer>
                <DrawerHeader />
                <div className={classes.page}>{children}</div>
            </Box>
        </div>
    );
}
